'use client'

import axios from 'axios'
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import { useDebounce } from '@/hooks/useDebounce'
import useGrantApplication from '@/hooks/useGrantApplication'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { GrantApplicationContext } from './grant.context'

import { IQuestion } from '@/types/chatbot'
import { Section } from '@/types/document'
import { GrantApplicationMode } from '@/types/grants'

export const GrantApplicationProvider = memo(
  ({ children }: PropsWithChildren) => {
    const [questions, setQuestions] = useState<IQuestion[]>([])
    const [steps, setSteps] = useState<{ [key: string]: any }>({})
    const [currentStep, setCurrentStep] = useState<number>(-1)
    const [isMatcherOpen, setIsMatcherOpen] = useState(true)
    const [mode, setMode] = useState<GrantApplicationMode>()
    const [{ sections, history, historyIndex }, setSections] = useState<{
      sections?: Section[]
      history: Section[][]
      historyIndex: number
    }>({
      sections: undefined,
      history: [],
      historyIndex: 0,
    })
    const { selectedConversation, mutateConversations } = useDrawerState()
    const { grantApplication } = useGrantApplication(selectedConversation)

    useEffect(() => {
      if (grantApplication) {
        setCurrentStep(grantApplication.currentStep)
        setMode(grantApplication.mode ?? GrantApplicationMode.RESEARCHING)
        setQuestions(grantApplication.questions)
        if (
          grantApplication.currentStep > 7 &&
          (!grantApplication.steps[8]?.sections ||
            grantApplication.steps[8]?.initialSectionsStatus !==
              'enhancedTwice')
        ) {
          if (
            grantApplication.steps[grantApplication.currentStep]?.numQuestions
          ) {
            setQuestions(grantApplication.questions.slice(0, -1))
          }
          setCurrentStep(7)
          setSections({
            sections: undefined,
            history: [],
            historyIndex: 0,
          })
          setSteps({ ...grantApplication.steps, 8: undefined })
        } else {
          if (grantApplication.steps) {
            setSections({
              sections: grantApplication.steps[8]?.sections,
              history: grantApplication.steps[8]?.sections
                ? [grantApplication.steps[8]?.sections]
                : [],
              historyIndex: 0,
            })
          }
          setSteps(grantApplication.steps)
        }
      } else if (!selectedConversation) {
        setSteps({})
        setCurrentStep(-1)
        setMode(undefined)
        setQuestions([])
        setSections({
          sections: undefined,
          history: [],
          historyIndex: 0,
        })
      }
    }, [grantApplication, selectedConversation])

    useEffect(() => {
      if (
        currentStep === 10 ||
        (mode === GrantApplicationMode.MATCHING && currentStep === 7) ||
        mode === GrantApplicationMode.RESEARCHING
      ) {
        setIsMatcherOpen(false)
      } else {
        setIsMatcherOpen(true)
      }
    }, [currentStep, mode])

    const handleUndo = () => {
      if (
        history === undefined ||
        historyIndex === undefined ||
        historyIndex === 0
      ) {
        return
      }
      setSections(
        ({ history: prevHistory, historyIndex: prevHistoryIndex }) => {
          const newSections = prevHistory[prevHistoryIndex - 1]
          setSteps({
            ...steps,
            8: {
              ...steps[8],
              sections: newSections,
            },
          })
          return {
            sections: newSections,
            history: prevHistory,
            historyIndex: prevHistoryIndex - 1,
          }
        }
      )
    }

    const handleRedo = () => {
      if (
        history === undefined ||
        historyIndex === undefined ||
        historyIndex === history.length - 1
      ) {
        return
      }
      setSections(
        ({ history: prevHistory, historyIndex: prevHistoryIndex }) => {
          const newSections = prevHistory[prevHistoryIndex + 1]
          setSteps({
            ...steps,
            8: {
              ...steps[8],
              sections: newSections,
            },
          })
          return {
            sections: newSections,
            history: prevHistory,
            historyIndex: prevHistoryIndex + 1,
          }
        }
      )
    }

    const isUndoDisabled = useMemo(
      () => historyIndex === undefined || historyIndex === 0,
      [historyIndex]
    )
    const isRedoDisabled = useMemo(
      () =>
        history === undefined ||
        historyIndex === undefined ||
        historyIndex === history.length - 1,
      [historyIndex, history]
    )

    /* 
      Everytime the currentStep or steps data changes, store the grant application to the database
      Exception: when the currentStep is 0, because the conversation is not yet created.
      Therefore we call storeToDb() in the useEffect in the AgentResponseStep component
    */
    useEffect(() => {
      if (currentStep > 0 && !!steps) {
        debouncedStoreToDb()
      }
    }, [currentStep, steps])

    const storeToDb = async () => {
      const config = {
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          id: selectedConversation,
          data: JSON.stringify({
            currentStep,
            steps,
            mode,
            questions,
          }),
        }),
      }

      await axios(`${API_URL}/grant-thread`, config)
    }

    const debouncedStoreToDb = useDebounce(() => {
      storeToDb().then(() => mutateConversations())
    }, 500)

    const contextValue = useMemo(() => {
      return {
        questions,
        steps,
        currentStep,
        mode,
        history,
        historyIndex,
        sections,
        isMatcherOpen,
        isUndoDisabled,
        isRedoDisabled,
        storeToDb,
        setCurrentStep,
        setMode,
        setSections,
        setSteps,
        setQuestions,
        setIsMatcherOpen,
        handleUndo,
        handleRedo,
      }
    }, [
      questions,
      steps,
      currentStep,
      mode,
      sections,
      history,
      historyIndex,
      isMatcherOpen,
      isUndoDisabled,
      isRedoDisabled,
      mutateConversations,
    ])

    return (
      <GrantApplicationContext.Provider value={contextValue}>
        {children}
      </GrantApplicationContext.Provider>
    )
  }
)
