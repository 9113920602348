import { IQuestion } from './chatbot'

export enum GrantApplicationMode {
  MATCHING = 'matching',
  DRAFTING = 'drafting',
  RESEARCHING = 'researching',
  CONTINUE_EXISTING = 'continue_existing',
}

export type GrantApplication = {
  questions: IQuestion[]
  steps: { [key: string]: any }
  currentStep: number
  mode?: GrantApplicationMode
}

export enum EnhanceType {
  LONGER = 'longer',
  SHORTER = 'shorter',
  DATA = 'data',
  CITE = 'cite',
}
