'use client'

import { memo, PropsWithChildren, useMemo, useState } from 'react'

import useAuth from '@/hooks/useAuth'
import useConversations from '@/hooks/useConversations'

import { DrawerContext } from '@/context/conversationDrawer/drawer.context'

export const DrawerProvider = memo(({ children }: PropsWithChildren) => {
  const [open, setOpenContext] = useState<boolean>(false)
  const [selectedConversation, setSelectedConversation] = useState<
    string | undefined
  >()
  const { user } = useAuth()
  const {
    mutate: mutateConversations,
    conversations,
    setSize,
    noMoreData,
  } = useConversations(6, user?.email)

  const show = () => {
    setOpenContext(true)
  }

  const close = () => {
    setOpenContext(false)
  }

  const contextValue = useMemo(() => {
    return {
      open,
      show,
      close,
      selectedConversation,
      setSelectedConversation,
      mutateConversations,
      conversations,
      setSize,
      noMoreData,
    }
  }, [open, selectedConversation, conversations, noMoreData])

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  )
})
